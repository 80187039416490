var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* import { Link as ScrollLink } from 'react-scroll'; */
var Navbar = function () {
    return (_jsx("header", __assign({ className: "bg-gray-800 text-white p-4 overflow-hidden" }, { children: _jsxs("nav", __assign({ className: "container mx-auto flex justify-between items-center overflow-hidden" }, { children: [_jsx("h1", __assign({ className: "text-xl font-bold" }, { children: "Al Gore" })), _jsx("div", {})] })) })));
};
export default Navbar;
