var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Spinner = function () {
    return (_jsx("div", __assign({ className: "flex items-center justify-center min-h-screen" }, { children: _jsx("div", { className: "loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32" }) })));
};
export default Spinner;
