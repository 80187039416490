var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FaGithub, FaLinkedin, FaEnvelope, FaTwitter } from 'react-icons/fa';
var Footer = function () {
    return (_jsx("footer", __assign({ className: "bg-gray-800 text-white p-6 mt-4" }, { children: _jsxs("div", __assign({ className: "container mx-auto text-center" }, { children: [_jsx("h2", __assign({ className: "text-2xl font-bold mb-4" }, { children: "Al Gore" })), _jsxs("p", __assign({ className: "mb-2" }, { children: [_jsx(FaEnvelope, { className: "inline mr-2" }), "ahgore@gmail.com"] })), _jsxs("p", __assign({ className: "mb-4" }, { children: [_jsx(FaEnvelope, { className: "inline mr-2" }), "(631) 306-4602"] })), _jsxs("div", __assign({ className: "flex justify-center space-x-4 mb-4" }, { children: [_jsx("a", __assign({ href: "https://github.com/DAaveMITT", target: "_blank", rel: "noopener noreferrer", "aria-label": "GitHub Profile" }, { children: _jsx(FaGithub, { size: 30, className: "hover:text-gray-400" }) })), _jsx("a", __assign({ href: "https://www.linkedin.com/in/alexandergore", target: "_blank", rel: "noopener noreferrer", "aria-label": "LinkedIn Profile" }, { children: _jsx(FaLinkedin, { size: 30, className: "hover:text-gray-400" }) })), _jsx("a", __assign({ href: "mailto:ahgore@gmail.com", "aria-label": "Email Address" }, { children: _jsx(FaEnvelope, { size: 30, className: "hover:text-gray-400" }) })), _jsx("a", __assign({ href: "https://twitter.com/DAaveMITT", target: "_blank", rel: "noopener noreferrer", "aria-label": "Twitter Profile" }, { children: _jsx(FaTwitter, { size: 30, className: "hover:text-gray-400" }) }))] })), _jsx("div", __assign({ className: "mt-4 text-sm" }, { children: "\u00A9 2024 Al Gore Portfolio. All rights reserved." }))] })) })));
};
export default Footer;
