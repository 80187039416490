var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Spinner from './pages/MainProjects/Spinner';
import Footer from './pages/Footer';
var Home = lazy(function () { return import('./pages/Home'); });
var Animations = lazy(function () { return import('./pages/Animations'); });
var App = function () {
    return (_jsxs(Router, { children: [_jsx(Navbar, {}), _jsx(Suspense, __assign({ fallback: _jsx(Spinner, {}) }, { children: _jsx(Routes, { children: _jsx(Route, { path: "/", element: _jsx(Home, {}) }) }) })), _jsx(Footer, {})] }));
};
export default App;
